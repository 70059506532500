<template>
  <esmp-datepicker-adaptive
    disabled
    :placeholder="placeholder"
    v-model="value"
  />
</template>

<script>
export default {
  name: 'GraphCalendarView',
  props: {
    settings: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      value: null,
    };
  },
  computed: {
    placeholder() {
      return this.settings.find((el) => el.techName === 'name').value || 'Дата';
    },
  },
};
</script>
